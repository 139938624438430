import React, { createContext, useContext, useEffect, useReducer } from "react";
import { mealReducer } from "../reducers/mealReducer"; // Import your reducer
import { startFetchCategories, startFetchSingleMeal, startFetchMealsBySearch, startFetchMealByCategory } from "../actions/mealsActions"; // Import actions

// Initial state
const initialState = {
    categories: [],
    categoryLoading: false,
    categoryError: false,
    categoryMeals: [],
    categoryMealsLoading: false,
    categoryMealsError: false,
    meals: [],
    mealsLoading: false,
    mealsError: false,
    meal: null,
    mealLoading: false,
    mealError: false
};

// Create context
const MealContext = createContext({});

// Provider component
export const MealProvider = ({ children }) => {
    const [state, dispatch] = useReducer(mealReducer, initialState); // useReducer with mealReducer and initialState

    // Fetch categories on initial load
    useEffect(() => {
        startFetchCategories(dispatch); // Fetch categories when the component mounts
    }, []);

    return (
        <MealContext.Provider value={{ 
            ...state, 
            dispatch, 
            startFetchCategories, 
            startFetchSingleMeal, 
            startFetchMealsBySearch, 
            startFetchMealByCategory
        }}>
            {children}
        </MealContext.Provider>
    );
};

// Custom hook to use the context
export const useMealContext = () => {
    return useContext(MealContext);
};
